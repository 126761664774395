import React from 'react';
import {ChargeToC,MyToast} from './styles';
import {useSpring} from 'react-spring';
import Flex from '../../../Flex';
import styled from '@emotion/styled';



export const ChargeToClose = ({text,width="0",time}) =>{
    return(
      <ChargeToC  width={width} time={time}>
        <span> {text}</span>
        <div className="charge"></div>
      </ChargeToC>
    )
};

export const Toast = ({text,type,show}) =>{

  let types = {
    info:{
      brd:"#81d4fa",
      bg:"#e1f5fe",
      icon:"info"
    },
    warning:{
      brd:"#ffcc80",
      bg:"#ffe0b2",
      icon:"warning"
    },
    error:{
      brd:"#ef9a9a",
      bg:"#ffebee",
      icon:""
    },
    success:{
      brd:"#a5d6a7",
      bg:"#e8f5e9",
      icon:"check"
    },
  };

  return(
   <React.Fragment>
     {show ?
       <MyToast
         className={"p-1"}
         brd={types[type].brd}
         bg={types[type].bg}
       >
         <Flex flex={"0 0 40px"} className={"p-2"}>
           <img src={`${process.env.AWS_MULTIMEDIA_S3}/dashboard/modal_icons/${types[type].icon}.png`} alt=""/>
         </Flex>
         <Flex flex={"1 0 70%"} jc={"flex-start"} className={"p-1"}>
           {text}
         </Flex>
       </MyToast>
       :
       null
     }
   </React.Fragment>
  )
};


const ContsCardSeleted = styled(Flex)`
  background:#f1f1f2;
  border-radius:5px;
  margin-bottom:5px;
  padding: .25em .75em;

  &:last-of-type{
    margin-bottom:0;
  }
`;

export const CardVinculated = ({mask,franchise,dues, type}) =>{
  return (
    <ContsCardSeleted className={"col-12"}>
      <Flex jc={"flex-start"} flex={"1 0 auto"}>
        <img src={`/img/cards/${franchise}.png`}   alt="" width={"40px"} height={"auto"} className={"mr-2"}/>
        <strong>****{mask}</strong>
      </Flex>
      <Flex flex={"0 0 80px"}>
        {type !=='' ? <strong>{Capitalize(type)}</strong>
        :<strong>{dues} cuota{dues > 1 && "s"}</strong>}
      </Flex>
    </ContsCardSeleted>
  )
}

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
