import React from 'react';
import Flex from '../Flex';
import Fade from '../Fade';

const ConfirmCardDelete = (correo,tarjeta,franchise)=>{
    return(
      <Fade show={true}>
        <Flex
          className={"wc"}
          direction={"column"}
        >
          <div className="wc pb-3 pt-4">
            <img
              src={process.env.AWS_MULTIMEDIA_S3 + "/dashboard/modal_icons/check.png"} 
              alt=""
              height={"70px"}
              width={"auto"}
              className={"mx-auto"}
            />
          </div>
          <div className="wc text-center">
            <p>Hemos enviado un email al correo <b>{correo}</b> para confirmar la eliminación del medio de pago.
              <span>
              <img src={`/img/cards/${franchise}.png`}
                   alt=""
                   height={"28px"}
                   width={"auto"}
                   className={"ml-3 mr-2"}
              />
            <b>****{tarjeta.slice(-4)}</b>
            </span>
            </p>
          </div>
        </Flex>
      </Fade>
    )
};

export default ConfirmCardDelete;
