import React from 'react';
import useGlobal from '../../../config/global';
import Btnt from '../../BtnT';
import { STEP } from '../../step';

function ModalPoseeSuscripcion() {
  const [globalState, globalActions] = useGlobal();

    return(
      <div className={"col-12 px-0"}>
        <div className="icon pt-4 pb-3">
          <img src={`${process.env.AWS_MULTIMEDIA_S3}/dashboard/modal_icons/info.png`} alt="Ícono de información" height={"50px"} width={"auto"}/>
        </div>
        <p className={"mb-0"}><strong>Usuario ya registrado</strong></p>
        <p> El usuario tiene suscripciones activas</p>
        <Btnt onClick={()=> {
          globalActions.setErrorAlert({message:[]})
          globalActions.setPoseeSuscripcion(false);
        } } text={"Agregar nueva suscripción"} btnClassname={"mb-2"}/>
        <Btnt onClick={()=>{
          globalActions.setStep(STEP.login);
          globalActions.setErrorAlert({message:[]})
          globalActions.setCustomerMongoId('');
          globalActions.setPoseeSuscripcion(false);
        }} type={"b"} text={"Cancelar"} btnClassname={"mb-3"}/>
      </div>
    )
}

export default React.memo(ModalPoseeSuscripcion);
