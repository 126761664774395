import React from 'react';
import Flex from '../../../Flex';
import styled from '@emotion/styled';

const Borde = styled(Flex)`
  max-width: 100%;
  margin: auto;
  color: #3a3a3a;
  margin-bottom: -1px;
  padding: 5px 20px 0;

  .dot {
    border: 1px dotted #cbcbcb;
  }

  .name {
    font-weight: bold;
    padding: 0 0.75em;
    font-size: 8pt;
    color: #9da8bb;
  }

  .value {
    padding: 0 0.75em;
    font-size: 8pt;
    text-align: right;
  }
`;

const HeaderPdf = styled(Flex)`
  width: 100%;
  padding: 20px;
  background-color: black;
  color: ${(props) => props.colorText};

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: bold;
  }
`;

const Sdato = styled.div`
  text-align: ${(props) => props.direction};
  margin-bottom: 15pt;

  .name {
    font-size: 8pt;
    text-transform: uppercase;
    color: black;
    lettering-spacing: 0.5pt;
  }

  .value {
    font-size: 8pt;
    text-transform: uppercase;
    color: #828691;
    lettering-spacing: 0.5pt;
  }
`;

const Titles = styled(Flex)`
  width: 100%;
  padding: 0 20pt;
  margin-bottom: 15pt;

  .texto {
    color: #868585;
    background-color: #e4e4e4;
    height: 30pt;
    text-align: center;
    width: 100%;
  }

  @media print {
    background-color: red;
  }
`;

const Title = ({ name }) => {
  return (
    <Titles flex={'0 0 auto'} className={'wc'}>
      <div className="texto">{name}</div>
    </Titles>
  );
};

const SimpleDato = ({ name, value, type }) => {
  return (
    <Sdato direction={type} className={'wc'}>
      <div className={'wc value'}>{name.toUpperCase()}</div>
      <div className={'wc name'}>{value}</div>
    </Sdato>
  );
};

const Totalc = styled.section`
  border: 1pt solid #d7d7d9;
  width: 100%;
  margin-bottom: 10pt;

  .estado {
    background-color: ${(props) => props.estado};
    color: white;
    font-size: 12pt;
    height: 27pt;
    text-align: center;
    padding: 8px;
  }

  .amount {
    padding: 8px;

    .number {
      font-size: 22pt;
      color: black;
    }
    .curr {
      font-size: 12pt;
      padding: 5pt 0 0 5pt;
      line-height: 12pt;
    }
  }
`;

const Total = ({
  estado = 'pendiente',
  total = '',
  currency = 'COP',
}) => {
  let estados = {
    aceptada: '#67C940',
    rechazado: '#E1251B',
    pendiente: '#FFD100',
  };

  let ColorEstado = estados[estado.toLowerCase()];

  return (
    <Totalc className={'wc'} estado={ColorEstado}>
      <div className={'wc estado'}>{estado.toUpperCase()}</div>
      <Flex className={'wc amount'} alg={'flex-end'}>
        <Flex flex={'0 0 auto'} className={'number'}>
          ${total}
        </Flex>
        <Flex flex={'0 0 auto'} className={'curr'}>
          {currency}
        </Flex>
      </Flex>
    </Totalc>
  );
};

const HeaderPDFP = ({
  logo,
  nombre,
  correo,
  telefono,
  numeroPago,
  fechaPago,
  estado,
  amount,
  currency,
}) => {
  return (
    <HeaderPdf alg={'flex-start'}>
      <Flex flex={'1 0 45%'} jc={'flex-start'}>
        <img
          src={logo}
          alt=""
          style={{ maxHeight: '60pt', maxWidth: '120pt' }}
          className={'mb-3'}
        />
        <SimpleDato name={'Nombre'} value={nombre} />
        <SimpleDato name={'correo'} value={correo} />
        <SimpleDato name={'teléfono'} value={telefono} />
      </Flex>
      <Flex flex={'1 0 45%'}>
        <Total estado={estado} total={amount} currency={currency} />

        <SimpleDato
          type={'right'}
          name={'Número de pago'}
          value={numeroPago}
        />
        <SimpleDato
          type={'right'}
          name={'fecha de pago'}
          value={fechaPago}
        />
      </Flex>
    </HeaderPdf>
  );
};

let IntemInfo = ({ name, value }) => {
  return (
    <Borde flex={'1 0 100%'} alg={'flex-end'}>
      <Flex flex={'0 0 auto'} jc={'flex-start'} className={'name'}>
        {name}
      </Flex>
      <Flex className={'dot'} flex={'1 0 100px'}></Flex>
      <Flex flex={'0 0 auto'} jc={'flex-end'} className={'value'}>
        {value}
      </Flex>
    </Borde>
  );
};

class PrintHistory extends React.Component {
  render() {
    const {
      paycoData: {
        x_transaction_id,
        x_transaction_date,
        x_ref_payco,
        x_id_factura,
        x_cardnumber,
        x_amount,

        //DATOS DEL CLIENTE
        // x_customer_email,
        // x_customer_name,
        // x_customer_phone,

        //DETALLE DEL COMERCIO
        x_business,
      },
      globalStatus,
      facturaId,
      invoiceData,
    } = this.props.data;

    let empresa  = {
      nombre:"Sin información.",
      contacto:"Sin información.",
      email:"Sin información."
    };

    let factura= [];

    if(invoiceData && invoiceData.empresa){
      empresa = invoiceData.empresa;
    }

    if(invoiceData && invoiceData.factura){
      factura = invoiceData.factura;
    }

    return (
      <Flex
        style={{ height: '100%', backgroundColor: 'red' }}
        className={'p-3 wc ro'}
        direction={'column'}
        jc={'flex-start'}
      >
        <HeaderPDFP
          color      ={this.props.color}
          colorText  ={this.props.colorText}
          comercio   ={x_business}
          logo       ={this.props.logo}
          estado     ={globalStatus}
          nombre     ={ empresa.nombre}
          correo     ={ empresa.email}
          telefono   ={ empresa.contacto}
          numeroPago ={x_transaction_id}
          fechaPago  ={x_transaction_date}
          amount     ={x_amount}
        />

        <Title name={'Detalle de formulario'} />
        <Flex flex={'0 0 auto'} className={'pb-5 wc'}>
          {factura.map((item, index) => (
              <IntemInfo
                name ={item.label}
                value={item.value}
                key  ={index}
                type ={item.format}
              />
            ))}
        </Flex>

        <Title name={'Detalle de transacción'} />

        <Flex flex={'0 0 auto'} className={'pb-5 wc'}>
          <IntemInfo name={'Referencia'} value={x_id_factura} />
          <IntemInfo name={'Referencia ePayco'} value={x_ref_payco} />
          <IntemInfo name={'Método de pago'} value={x_cardnumber} />
          <IntemInfo name={'Número de recibo'} value={facturaId} />
        </Flex>

        <Flex flex={'1 0 auto'}></Flex>
        <Flex
          flex={'0 0 auto'}
          jc={'flex-end'}
          className={'wc py-3 px-5 mx-auto'}
        >
          Powered by{' '}
          <img
            className={'ml-2'}
            src={process.env.AWS_MULTIMEDIA_S3 + "/dashboard/logo-epayco-new.svg"}
            alt=""
          />
        </Flex>
      </Flex>
    );
  }
}

export default PrintHistory;
