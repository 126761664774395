import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  width:${props => props.width};
  margin:10px auto;

  img{
    width:100%;
    height:auto;
    display:block;
  }
`

const IconAlert = ({type, width = "70px" }) => {

  // type may be
  // error
  // info
  // check
  // warning

    return(
      <Container width={width}>
        <img
          src={`${process.env.AWS_MULTIMEDIA_S3}/dashboard/modal_icons/${type}.png`}
          alt=""
        />
      </Container>
    )
};

export default IconAlert;
