import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PDFDownloadLink } from '@react-pdf/renderer';
import is from 'is_js';

/**
 * Component
 */
import Titlesection from '../../../TitleSection';
import Flex from '../../../Flex';
import Loading from '../../../loading';
import { BtnIcon } from '../../../BtnT/BtnT';

/**
 * Style
 */
import { DataItem } from './style';

/**
 * Query
 */
import { HISTORY_DOMICILIATION } from '../../../../data/queries/domiciliation';

/* Mutations */
import { GET_IMAGES } from '../../../../data/mutations/payment';

/**
 * Global
 */
import useGlobal from '../../../../config/global';

/**
 * Helpers
 */
import { FormatDate, FormatMoney } from '../../../../util/format';
import Notfound from '../../../notFound';
import Fade from '../../../Fade';
import { PdfHistory } from './pdf/pdf';
import PrintHistory from './printPdf';
import { getCorrectTextColor } from '../../../getContrastColor';

function History({ returnAction }) {
  const [globalState, globalActions] = useGlobal();
  const [dataHistory, setDataHistory] = useState(null);
  const printRef = useRef();

  const [buffer, setBuffer] = useState({});
  const [showPdf] = useState(false);

  const {
    transaction: { epaycoSubscription },
  } = globalState;

  const { loading, error, data } = useQuery(HISTORY_DOMICILIATION, {
    variables: {
      idDomiciliation: String(epaycoSubscription.suscripcionId),
      idTransaction: String(globalState.idTransaction),
    },
  });

  const [getImages] = useMutation(GET_IMAGES, {
    onError: () => {},
    onCompleted: (Data) => {
      setBuffer({
        bufferLogo: Buffer.from(Data.getImages.bufferLogo, 'base64'),
        bufferEpayco: Buffer.from(
          Data.getImages.bufferEpayco,
          'base64'
        ),
      });
    },
  });

  useEffect(() => {
    getImages({
      variables: {
        idTransaction: globalState.idTransaction,
        url:
          `${process.env.AWS_MULTIMEDIA_S3}/Brand/PNG/epayco.png`, //URL IMAGEN EPAYCO
      },
    });
  }, []);

  /**
   * Error data
   */
  useEffect(() => {
    if (is.not.undefined(error)) {
      globalActions.setErrorAlert({
        message: 'hola men',
        content: () => error,
      });
    }
  }, [error]);

  /**
   * Set data
   */
  useEffect(() => {
    if (is.not.undefined(data)) {
      setDataHistory(data['historyDomiciliation']);
    }
  }, [data]);

  if (loading) {
    return (
      <div className={'wc py-5 px-3 px-sm-4'}>
        <Loading
          show={true}
          texto={'Generando historial de pagos...'}
        />
      </div>
    );
  }

  return (
    <div className={'px-3 px-sm-4 pb-3'}>
      <Fade show={true}>
        <Titlesection
          text={'Historial de pagos'}
          back={() => returnAction()}
          backtext={'Volver'}
        />
      </Fade>
      {dataHistory && dataHistory.payments.length ? (
        dataHistory.payments.map((item, index) => {
          if (item) {
            const {
              paycoData: {
                x_transaction_id,
                x_transaction_date,
                x_ref_payco,
                x_id_factura,
                x_cardnumber,
                x_amount,
              },
              globalStatus,
              facturaId,
            } = item;

            return (
              <Fade show={true} key={index}>
                <DataItem
                  visibles={{
                    'Número transacción': x_transaction_id,
                    'Estado|estado': globalStatus,
                    'Fecha de pago': FormatDate(
                      x_transaction_date,
                      'DD/MM/yyyy'
                    ),
                  }}
                  ocultos={{
                    // Plan: '4552639568',
                    // 'Ref pago': '1254212',
                    Valor: FormatMoney(x_amount),
                    'Medio de pago': (
                      <span>
                        <img
                          src={`/img/cards/${item.card.metadata.name}.png`}
                          height={'28px'}
                          width={'auto'}
                          className={'mr-1'}
                        />
                        **** {x_cardnumber.slice(-4)}
                      </span>
                    ),
                    // Autorización: '212452',
                    'Ref comercio': x_id_factura,
                    'Ref epayco': x_ref_payco,
                    Recibo: facturaId,
                  }}
                  acciones={() => (
                    <Flex jc={'flex-end'}>
                      <ReactToPrint
                        trigger={() => <BtnIcon icon={'print'} />}
                        content={() => printRef.current}
                      />
                      <div
                        style={{
                          display: showPdf ? 'block' : 'none',
                        }}
                      >
                        <PrintHistory
                          ref={printRef}
                          data={item}
                          color={'#' + globalState.commerce.colorLogo}
                          colorText={getCorrectTextColor(
                            '#' + globalState.commerce.colorLogo
                          )}
                          comercio={globalState.commerce.comercio}
                          logo={globalState.commerce.logo}
                        />
                      </div>
                      <PDFDownloadLink
                        document={
                          <PdfHistory image={buffer} data={item} />
                        }
                        fileName={`Comprobante_de_Pago_${globalState.commerce.comercio}_${x_transaction_id}.pdf`}
                      >
                        <BtnIcon
                          icon={'vertical_align_bottom'}
                          className={'ml-1'}
                        />
                      </PDFDownloadLink>
                    </Flex>
                  )}
                />
              </Fade>
            );
          }
        })
      ) : (
        <Fade show={true}>
          <Notfound
            text={<span>No se encontraron registros de pago.</span>}
          />
        </Fade>
      )}
    </div>
  );
}

History.propTypes = {
  returnAction: PropTypes.func.isRequired,
};

History.defaultProps = {
  returnAction: () => console.log('return action'),
};

export default React.memo(History);
