import React, { useState, useEffect, useRef } from 'react';
import Input from '../../../input';
import { useMutation } from '@apollo/react-hooks';

/**
 * Styles
 */
import { Container } from './styles';

/**
 * Components
 */
import { STEP } from '../../../step';
import Loading from '../../../loading';

/**
 * Global
 */
import useGlobal from '../../../../config/global';

/**
 * Mutation
 */

import { VALIDATE_RANDOM_DISCOUNT } from '../../../../data/mutations/payment';

const ValidateRandomDiscount = ({validateRandomDiscountRef}) => {
  const [state, setState] = useState({
    code: '',
    processing: false,
    error: '',
    autofocus:false,
    entro:false
  });

  const [attempts, setAttempts] = useState(0);

  const [globalState, globalActions] = useGlobal();

  let validateCodeRef = useRef(null);

  const [validateDiscount] = useMutation(VALIDATE_RANDOM_DISCOUNT, {
    onError: () => {
      globalActions.setMainButton({
        ...globalState.mainButton,
        loading: false,
      });
      globalActions.setErrorAlert({
        icon: 'error',
        title: '¡Error!',
        message: [
          `Ocurrió un error al validar el valor provisto`,
        ],
        content: false,
        hideButtons: false,
        btnText: 'Aceptar',
      });
    },
    onCompleted: ({ validateRandomDiscount: response }) => {
      globalActions.setMainButton({
        ...globalState.mainButton,
        loading: false,
      });

      if (response.responseInfo.success) {
        actualizaEstado({ processing: false });
        globalActions.setStep(STEP.sms);
      } else {
        const currentAttempts = attempts + 1;
        
        if (currentAttempts > 2) {
          globalActions.setErrorAlert({
            icon: 'error',
            title: '¡Error!',
            message: [
              `Usted ha utilizado el máximo de 3 intentos para introducir el valor aleatorio`,
            ],
            content: false,
            hideButtons: false,
            btnText: 'Aceptar',
            btnCallback: () => {
              globalActions.setStep('methods');
              globalActions.setDirection(true);
            },
          });
        } else {
          setAttempts(currentAttempts);
          const remainingAttempts = `intento${currentAttempts === 2 ? "" : "s"} restante${currentAttempts === 2 ? "" : "s"}`
          actualizaEstado({
            error: `El valor ingresado es incorrecto, ${3 - currentAttempts} ${remainingAttempts}`
          });
        }
      }
    }
  });

  const actualizaEstado = x => setState(Object.assign({}, state, x));

  useEffect(()=>{
    if(globalState.isAnimating){
      setState({...state,entro:true})
    }
    if(state.entro && !globalState.isAnimating){
      validateCodeRef.current.focus();
    }
  },[globalState]);

  let colorT =
    globalState.commerce.colorLogo !== undefined
      ? '#' + globalState.commerce.colorLogo
      : '#323232';

  return (
    <Container
      className="validateCode p-4"
      direction={'column'}
      jc={'flex-start'}
      color={colorT}
    >
      <div className="wc pt-5 text-center">
        <img
          src={ process.env.AWS_MULTIMEDIA_S3 +"/dashboard/modal_icons/warning.png"}
          alt=""
          height={'70px'}
          width={'auto'}
          className={'mx-auto mb-3'}
        />
        <p>
          Ingrese el valor descontado de su tarjeta como prueba. Puede validar este valor ingresando al APP de su entidad bancaria o revisar los mensajes de texto recibidos en su celular
        </p>
      </div>
      <form
        className={'wc'}
        ref={validateRandomDiscountRef}
        onSubmit={e => {
          e.preventDefault();
          validateCodeRef.current.blur();

          globalActions.setMainButton({
            ...globalState.mainButton,
            loading: true,
          });
          
          validateDiscount({
            variables: {
              token: globalState.token,
              randomDiscountValue: state.code
            }
          });
        }}
      >
        <Input
          icon={'lock'}
          type={"number"}
          value={state.code}
          onChange={e => {
            actualizaEstado({
              code: e.target.value,
              error: '',
            });
          }}
          error={state.error !== '' && state.error}
          disabled={globalState.mainButton.loading}
          autoFocus={state.autofocus}
          REF={validateCodeRef}
        />
      </form>
    </Container>
  );
}

export default React.memo(ValidateRandomDiscount);